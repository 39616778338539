.containerBox {
  width: 100%;
  background-color: #ffffff;
  position: relative;
  -moz-box-shadow: 0 0 18px -1px #e8e8e8;
  -webkit-box-shadow: 0 0 18px -1px #e8e8e8;
  box-shadow: 0 0 18px -1px #e8e8e8;
  border-radius: 8px;
  height: 709px;
}

@media screen and (max-width: 1330px) {
  .containerBox {
    margin-left: 0px;
    height: 720px;
  }
}
