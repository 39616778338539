div.google-visualization {
  background-color: blue !important;
}

div.google-visualization-tooltip {
  background-color: #ffffff;
  -moz-box-shadow: 0 0 18px -1px #e8e8e8;
  -webkit-box-shadow: 0 0 18px -1px #e8e8e8;
  box-shadow: 0 0 18px -1px #e8e8e8;
  border-radius: 8px;
  border: none;
  font-size: 12px !important;
  font-weight: 300;
}

div.google-visualization-tooltip > ul > li > span {
  font-family: "nunitoSans Regular", sans-serif !important;
}
