body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'NunitoSans Light';
  src: url(./fonts/NunitoSans-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'NunitoSans Regular';
  src: url(./fonts/NunitoSans-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'NunitoSans Bold';
  src: url(./fonts/NunitoSans-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'NunitoSans SemiBold';
  src: url(./fonts/NunitoSans-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'NunitoSans ExtraBold';
  src: url(./fonts/NunitoSans-ExtraBold.ttf) format('opentype');
}
