::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px transparent;
    border: solid 4px transparent;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 14px 14px #bbbbbe;
    border: solid 4px transparent;
    border-radius: 8px;
    height: 80px;
}

::-webkit-scrollbar-button {
    display: none;
}

.MuiCheckbox-colorSecondary.Mui-checked{
    color: #0EA789;
}