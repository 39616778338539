.container-logout {
  position: fixed;
  top: 28%;
  left: 42%;
  margin-top: -50px;
  margin-left: -100px;
  background: #ffffff;
  width: 26rem;
  height: 26rem;

  -moz-box-shadow: 0 0 18px -1px #e8e8e8;
  -webkit-box-shadow: 0 0 18px -1px #e8e8e8;
  box-shadow: 0 0 18px -1px #e8e8e8;
  border-radius: 8px;
}

.caixa-logout {
  width: 26rem;
  height: 30rem;
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: center;
}

.imagem-logout {
  width: 13rem;
  margin-top: 3rem;
}

.grupo-logout {
  margin: 2px;
  width: 80%;
}

.inputUsuario {
  margin-bottom: 2rem;
  width: 100%;
}

.inputSenha {
  margin-bottom: 1.5rem;
  width: 100%;
}

.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(2px, -16px) scale(0.85) !important;
}

.MuiInputLabel-filled.MuiInputLabel-marginDense {
  transform: translate(8px, 10px) scale(1) !important;
}