
.MuiPickersSlideTransition-root.MuiPickersCalendar-root{
  min-height: 220px !important;
}
.MuiPickersBasePicker-pickerView.MuiPickersClock-root {
  min-height: 300px !important;
}


.MuiPickersCalendarHeader-monthTitleContainer {
  color: #1D1E1F;
  text-transform: capitalize;
  font-family: 'NunitoSans SemiBold', sans-serif;
}

.MuiPickersStaticWrapper-root{
  border-radius: 8px !important;
}
